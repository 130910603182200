<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="editMenthorValidation">
            <b-form @submit.prevent>
                <b-card>
                    <template v-if='menthor'>

                        <b-row>
                            <b-col><h3>{{ $t("general.edit_menthor") }}</h3></b-col>
                            <b-col class="text-right">
                                <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols='12' sm='6'>
                                <validation-provider
                                    :name="$t('general.name')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='name'>{{ $t('general.name') }}</label>
                                    <b-form-input v-model="menthor.Firstname" name="name" id="name"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                                <validation-provider
                                    :name="$t('general.lastname')"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <label for='lastname'>{{ $t('general.lastname') }}</label>
                                    <b-form-input v-model="menthor.Lastname" name="lastname" id="lastname"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col  cols='12' sm='6' class="mt-1 mt-sm-0">
                                <label for='profTitle'>{{ $t('general.profTitle') }}</label>
                                <b-form-input v-model="menthor.ProfessionalTitle" name="profTitle" id="profTitle"></b-form-input>
                            </b-col>
                            <b-col  cols='12' sm='6' class="mt-1 mt-sm-0">
                                <validation-provider
                                    :name="$t('general.emailAddress')"
                                    #default="{ errors }"
                                    rules="email"
                                >
                                    <label for='email'>{{ $t('general.emailAddress') }}</label>
                                    <b-form-input v-model="menthor.Email" name="email" id="email"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right">
                                <b-button @click="validationForm"  :disabled="!isChangingPermitted" variant="primary" class="mt-2">{{ $t('general.save') }}</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required, email } from '@validations'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput, ValidationProvider, ValidationObserver},
        data() {
            return {
                showLoader: false,
 
                menthor: null,
                isChangingPermitted: false,

                id: 0,
                school_id: 0,

                required,
                email

            }
        },
        methods: {
            validationForm() {
    
                this.$refs.editMenthorValidation.validate().then((response) => {
                    if (response) {
                        this.onEditMenthor()
                    }
                })
            },
            async loadMenthor() {
                const thisIns = this
                thisIns.showLoader = true
                await thisIns.$http.get(`/v1/sola/mentorji/${this.id}`)
                    .then(response => {
                        thisIns.menthor = response.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)

                        thisIns.showLoader = false
                    })
            },
            onEditMenthor() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Firstname: thisIns.menthor.Firstname.charAt(0).toUpperCase() + thisIns.menthor.Firstname.slice(1).toLowerCase(),
                    Lastname: thisIns.menthor.Lastname.charAt(0).toUpperCase() + thisIns.menthor.Lastname.slice(1).toLowerCase(),
                    ProfessionalTitle: thisIns.menthor.ProfessionalTitle,
                    Email: thisIns.menthor.Email
                }

                thisIns.$http.patch(`/v1/sola/mentorji/${this.id}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.menthor_successfully_edited')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'menthors'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            async checkIfChangeIsPermitted() {
                this.isChangingPermitted = await this.$checkIfChangeIsPermitted()

                if (!this.isChangingPermitted) this.$router.replace('/')

            }

        },
        created()  {
            this.id = this.$route.params.id
        },
        async mounted() {
            await this.loadMenthor()
            await this.checkIfChangeIsPermitted()
        }
    }
</script>